@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("/src/assets/font/Basier-Square-regular-webfont/stylesheet.css");

.light {
  --dark-primery: 223, 144, 27; /*#DF901B */
  --transaction-bg: 239, 125, 34, 1; /* #EF7D22  icon-btn */
  --button-hover-bg: 217, 107, 30, 1; /* #D96B1E icon-btn-hover */
  --button-bg: 239, 125, 34, 1; /* #EF7D22 */
  --button-text: 255, 255, 255, 1; /* #FFFFFF */
  --input-bg: 255, 255, 255, 1; /* #FFFFFF  Monfi input-inactive */
  --active-state-bg: 239, 125, 34, 0.4; /* #EF7D22 */
  --body-bg: 244, 244, 244, 1; /* #F4F4F4 */
  --body-text: 8, 28, 48, 1; /* #081C30 Monfi/text-primary */
  --highlighter-green: 22, 126, 66, 1; /* #167E42 */
  --divider-border: 229, 229, 231, 1; /* #E5E5E7 */
  --popup-bg: 255, 255, 255, 1; /* #FFFFFF */
  --active-dropdown: 233, 232, 227, 1; /* #E9E8E3 */
  --dropdown-popup-bg: 255, 255, 255, 1; /* #FFFFFF */
  --highlighter-red: 236, 12, 0; /* #EC0C00 */
  --hover-state-bg: 223, 219, 211, 1; /* #DFDBD3 */
  --icon-btn: 239, 125, 34, 1; /* #EF7D22 */
  --icon-btn-hover: 239, 125, 34, 0.5; /* #EF7D22 */
  --input-active-bg: 255, 255, 255, 1; /* #FFFFFF */
  --option-list-hover: 244, 138, 61, 0.15; /* #F48A3D */
  --option-list-selected: 255, 241, 230, 1; /* #FFF1E6 Monfi/activeselection */
  --paragraph-text: 85, 90, 99, 1; /* #555A63 Monfi/label-text */
  --placeholder-text: 23, 44, 15, 0.5; /* new color from monfi #172c0f */
  --primary-item: 8, 28, 48, 1; /*#081C30  Monfi/TextSecondary */
  --primary-Icon-bg: 255, 255, 255, 1; /* #FFFFFF */
  --secondary-icon-bg: 224, 230, 244, 1; /* #E0E6F4 custome color */
  --tabs-gradient: 255, 255, 255, 1; /* #FFFFFF */
  --search-bg: 216, 219, 216, 1; /* new color from monfi #d8dbd8*/
  --transactions-icon-bg: 107, 122, 101, 1; /*#6b7a65*/
  --skeleton-start: 249, 249, 246, 1; /* #F9F9F6 */
  --skeleton-end: 223, 219, 211, 1; /* #6b7a65 with opacity*/
  --table-row-bg: 80, 60, 45, 0.4; /* #2C3B4A */
  --card-hide-balance-btn: 154, 164, 174, 1; /* #9AA4AE Monfi/label-text */

  --primarycard-gradient: linear-gradient(
    180deg,
    rgba(221, 222, 224, 0.6) 45.39%,
    rgba(247, 240, 234, 0.6) 99.92%
  );

  --table-body-gradient: linear-gradient(
    180deg,
    rgba(221, 222, 224, 0.6) 45.39%,
    rgba(247, 240, 234, 0.6) 99.92%
  );

  --side-gradient: linear-gradient(
    0deg,
    #253d35 20.71%,
    #253d35 24.74%,
    #3a545356 100%
  );
}

.dark {
  --dark-primery: 239, 198, 96, 1; /*#EFC660 */
  --option-list-selected: 40 23 10; /* #28170A Monfi/activeselection */
  --transaction-bg: 239, 125, 34, 1; /* #EF7D22  icon-btn */
  --button-hover-bg: 244, 138, 61, 1; /* #F48A3D icon-btn-hover */
  --button-bg: 239, 125, 34; /* #EF7D22  icon-btn */
  --button-text: 8, 28, 48, 1; /* #081C30 Monfi/text-btn-primary */
  --input-bg: 29, 30, 32, 1; /* #1D1E20 Monfi input-inactive */
  --active-state-bg: 239, 125, 34, 0.5; /* #EF7D22 */
  --body-bg: 15, 15, 15, 1; /* #0F0F0F */
  --body-text: 231, 238, 235, 1; /* #e7eeeb Monfi/text-primary */
  --highlighter-green: 21, 191, 93, 1; /* #15BF5D */
  --divider-border: 58, 58, 57, 1; /* #3A3A39 */
  --highlighter-red: 254, 67, 57; /* #FE4339 */
  --hover-state-bg: 244, 138, 61, 0.3; /*#F48A3D #  Monfi/*/
  --icon-btn: 239, 125, 34, 1; /* #EF7D22 */
  --icon-btn-hover: 239, 125, 34, 0.5; /* #EF7D22 */
  --paragraph-text: 154, 164, 174, 1; /* #9AA4AE Monfi/label-text */
  --placeholder-text: 127, 132, 137, 1; /* new color from monfi #7F8489 */
  --popup-bg: 32, 32, 30, 1; /* #20201E */
  --active-dropdown: 63, 63, 59, 1; /* #3F3F3B */
  --primary-item: 239, 125, 34, 1; /*##EF7D22 #  Monfi/TextSecondary*/
  --primary-Icon-bg: 12, 31, 49, 1; /* #0C1F31 */
  --input-active-bg: 26, 27, 29, 1; /* #1A1B1D */
  --secondary-icon-bg: 44, 59, 74, 1; /* #2C3B4A custom color */
  --dropdown-popup-bg: 32, 32, 30, 1; /* #20201E */
  --option-list-hover: 244, 138, 61, 0.1; /* #F48A3D */
  --primary-shade-color: 239, 198, 96, 0.3; /* ##EFC6604D */
  --skeleton-start: 63, 63, 59, 1; /* #3F3F3B */
  --skeleton-end: 80, 60, 45, 0.4;
  --transactions-icon-bg: 68, 75, 65, 1; /*#444b41*/
  --search-bg: 39, 42, 40, 1; /* new color from monfi #272a28*/
  --table-header-bg: 61, 73, 85, 1; /* #20201E */
  --table-row-bg: 80, 60, 45, 0.4; /* #2C3B4A */
  --card-hide-balance-btn: 154, 164, 174, 1; /* #9AA4AE Monfi/label-text */
  --tabs-gradient: linear-gradient(
    95deg,
    #1b1b19 17.71%,
    #292928 51.74%,
    #1b1b19 89.56%
  );

  --table-gradient: linear-gradient(
    90deg,
    rgba(61, 73, 85, 0.2) 45.39%,
    rgba(80, 60, 45, 0.4) 99.92%
  );

  /* --table-body-gradient: linear-gradient(
    180deg,
    rgba(80, 60, 45, 0.5) 45.39%,
    rgba(80, 60, 45, 0.4) 99.92%
  ); */

  --primarycard-gradient: linear-gradient(
    180deg,
    rgba(61, 73, 85, 0.8) 45.39%,
    rgba(80, 60, 45, 0.8) 99.92%
  );

  --side-gradient: linear-gradient(
    0deg,
    #253d35 20.71%,
    #253d35 24.74%,
    #3a545356 100%
  );
}

/* Keyframes for the skeleton animation */
@keyframes changeColor-light {
  0% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 0%, rgba(61, 73, 85, 0.1) 46%);
  }
  40% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 20%, rgba(61, 73, 85, 0.1) 66%);
  }
  60% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 20%, rgba(61, 73, 85, 0.1) 66%);
  }
  80% {
    background: linear-gradient(90deg, rgba(61, 73, 85, 0.1) 40%, rgba(249, 249, 246, 1) 76%);
  }
  100% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 80%, rgba(61, 73, 85, 0.1) 96%);
  }
}
@keyframes changeColor-dark {
  0% {
    background: linear-gradient(
      90deg,
      rgba(61, 73, 85, 0.8) 0%,
      /* rgba(23, 44, 15, 1) 46% */ rgba(80, 60, 45, 0.7) 46%
    );
  }
  40% {
    background: linear-gradient(
      90deg,
      rgba(61, 73, 85, 0.8) 20%,
      /* rgba(23, 44, 15, 1) 66% */ rgba(80, 60, 45, 0.7) 66%
    );
  }
  60% {
    background: linear-gradient(
      90deg,
      rgba(61, 73, 85, 0.8) 20%,
      /* rgba(23, 44, 15, 1) 66% */ rgba(80, 60, 45, 0.7) 66%
    );
  }
  80% {
    background: linear-gradient(
      90deg,
      rgba(61, 73, 85, 0.8) 40%,
      /* rgba(23, 44, 15, 1) 76% */ rgba(80, 60, 45, 0.7) 76%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      rgba(61, 73, 85, 0.8) 80%,
      /* rgba(23, 44, 15, 1) 96% */ rgba(80, 60, 45, 0.8) 96%
    );
  }
}

/* Apply the animation to the element */
.light .skeleton-animation {
  animation: changeColor-light 2s ease infinite; /* Change the duration (3s) as needed */
}
.dark .skeleton-animation {
  animation: changeColor-dark 2s ease infinite; /* Change the duration (3s) as needed */
}
/* Hide scrollbar for all browsers 
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;  
}
::-webkit-scrollbar {
  display: none;
}

*/

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar {
  scrollbar-color: transparent, transparent;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #d9d9d9;
}

/* For IE, Edge, and Firefox */
html,
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  overflow: scroll; /* Ensure scrolling is enabled */
}

/* Custom CSS to hide arrows */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrows {
  -moz-appearance: textfield;
}

/* Hide scrollbar for all browsers */
.hide-scrollbar {
  /* Chrome, Safari, and Opera */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
  /* Hide scrollbar for all other browsers */
  /* overflow: hidden;  */
}

.light .autofill-input {
  transition: background-color 5000s ease-in-out 0s;
}

.light .autofill-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #f0f0f0 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.light .autofill-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #000 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.light .autofill-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #000 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.dark .autofill-input {
  transition: background-color 5000s ease-in-out 0s;
}

.dark .autofill-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #f0f0f0 to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

.dark .autofill-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #ffff to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

.dark .autofill-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #ffff to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

/* Others Accounts Border Animations */
@keyframes borderAnimation {
  0% {
    border-top-color: #F48A3D;
    border-right-color: #EF7D22;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  25% {
    border-top-color: transparent;
    border-right-color: #F48A3D;
    border-bottom-color: #EF7D22;
    border-left-color: transparent;
  }
  50% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #F48A3D;
    border-left-color: #EF7D22;
  }
  75% {
    border-top-color: #EF7D22;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #F48A3D;
  }
  100% {
    border-top-color: #EF7D22;
    border-right-color: #F48A3D;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.borderAnimation:hover {
  animation: borderAnimation 2s linear infinite;
  border-style: solid;
  border-width: 1px;
  border-color: transparent; /* Initial color */
}

.slick-dots li button:before {
  @apply text-body-text !important;
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
  @apply text-primary-item !important;
}

.slick-slide > div {
  margin: 0 10px;
}

@layer utilities {
  @keyframes breathing {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
  }

  .animate-breathing {
    animation: breathing 2s infinite;
  }

  @keyframes progress {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .animate-progress {
    animation: progress 1.5s linear infinite;
  }
}
