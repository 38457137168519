/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 5, 2018 */



@font-face {
    font-family: 'BasierSquare';
    src: url('basiersquare-regular-webfont.eot');
    src: url('basiersquare-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('basiersquare-regular-webfont.woff2') format('woff2'),
         url('basiersquare-regular-webfont.woff') format('woff'),
         url('basiersquare-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}