.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-calendar {
  width: 100%;
  max-width: 360px;
  background: rgba(var(--dropdown-popup-bg));
  font-weight: bold;
  padding: 10px;
  line-height: 1.125em;
  border-radius: 15px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__label__labelText {
  background-color: rgba(var(--active-state-bg));
  font-size: 14px;
  border-radius: 100px;
  padding: 6px 12px;
  color: rgba(var(--primary-item));
  font-weight: 500;
}

.react-calendar__navigation__label:hover {
  background-color: transparent;
}

.react-calendar__navigation__arrow {
  background-color: rgba(var(--secondary-icon-bg)) !important;
  border-radius: 50%;
  font-size: 30px;
  color: rgba(var(--primary-item));
  margin: 0px 20px;
}

.react-calendar__navigation {
  display: flex;
  height: 40px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 40px;
  background: none;
}

.react-calendar__month-view__weekdays {
  color: rgba(var(--body-text));
  text-align: center;
  font: inherit;
  font-size: 0.75em;
  opacity: 0.6;
  padding: 4px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: rgba(var(--body-text));
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px;
  color: rgba(var(--body-text));
  background: none;
  text-align: center;
  line-height: normal;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
}

.react-calendar__tile:disabled {
  color: rgba(var(--body-text));
  opacity: 0.3;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(var(--active-state-bg));
}

.react-calendar__tile--now {
  background-color: rgba(var(--primary-Icon-bg));
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus .react-calendar__tile--now:hover {
  background-color: rgba(var(--secondary-icon-bg));
}

.react-calendar__tile--active {
  background-color: rgba(var(--primary-Icon-bg));
  color: rgba(var(--primary-item));
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: rgba(var(--active-state-bg));
}

.react-calendar__tile--active:hover {
  background-color: rgba(var(--primary-Icon-bg));
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
